<template>
  <div class="search-surnames-results-empty results-empty">
    <h5>No match found for "{{ searchText }}"</h5>
  </div>
</template>

<script>
export default {
  props: {
    searchText: String,
  },
  components: {},
};
</script>

<style lang="scss" scoped></style>
