<template>
  <div class="search-surnames-contaienr">
    <form class="filter" autocomplete="off" action="" @submit.enter.prevent="onInputEnter()">
      <label for="search-input">Search for a surname</label>
      <input
        id="search-input"
        class="input"
        :placeholder="searchInputPlaceholder"
        :value="surnamesSearchFormTextState"
        @change="onSearchChange"
      />
      <div v-if="errors.search" class="error">{{ errors.search }}</div>
    </form>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    errors: Object,
  },
  computed: {
    ...mapGetters(['surnamesSearchFormTextState']),
    searchInputPlaceholder() {
      return `e.g. "Huang"`;
    },
  },
  methods: {
    onSearchChange(event) {
      this.$store.commit('setSurnamesSearchFormTextState', event.target.value.trim());
    },
    onInputEnter() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped></style>
