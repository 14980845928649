<template>
  <div class="search-surnames-page">
    <div class="limited_content">
      <h4>Search Chinese Surnames</h4>
      <div class="search-content-wrapper">
        <div class="search-controls">
          <search-surnames-form-container :errors="errors" @submit="onSubmit"></search-surnames-form-container>
          <mcr-button class="search-button" :loading="surnameExistsLoadingState" @click="onSubmit">Search</mcr-button>
        </div>
        <div class="search-content search-results-content">
          <mcr-loading-indicator v-if="surnameExistsLoadingState" :loading="true"></mcr-loading-indicator>
          <div class="search-results-container search-tips">
            <div class="getting-started" v-if="!searchTouched">
              <div class="bold">Trace the history and meaning of your Chinese surname.</div>
              <p>
                Translate your surname to Chinese, discover the origins behind your name, and find records of relatives
                with the same surname!
              </p>
            </div>
            <div class="getting-started">
              <alphabet-search @click="onAlphabetLetterClick"></alphabet-search>
            </div>
            <mcr-loading-indicator v-if="surnamesLoadingState" :loading="true"></mcr-loading-indicator>
            <search-surnames-results
              v-else-if="!notFoundSurname"
              @onSwitchPage="onSwitchPage"
            ></search-surnames-results>
            <search-surnames-results-empty
              v-if="notFoundSurname"
              :search-text="notFoundSurname"
            ></search-surnames-results-empty>
            <div class="records-section" v-if="searchCategoriesListState.length">
              <div class="bold">Your surname on My China Roots</div>
              <div class="records-items">
                <router-link :to="searchRecordsRoute">
                  <stand-out-block-image :img-src="searchRecordsCategoryImage">
                    <div slot="description">Discover millions of Overseas Chinese records.</div>
                  </stand-out-block-image>
                </router-link>
                <router-link :to="searchZupusRoute">
                  <stand-out-block-image :img-src="searchZupusCategoryImage">
                    <div slot="description">With your Chinese surname, unlock family tree books in China.</div>
                  </stand-out-block-image>
                </router-link>
              </div>
            </div>
            <div class="faq">
              <div class="bold">Frequently Asked Questions</div>
              <ul class="desc">
                <li>
                  <mcr-wiki-glossary-link
                    naturalId="whySurnameCharacter"
                    text="Why should I find character for my surname?"
                  />
                </li>
                <li>
                  <mcr-wiki-glossary-link naturalId="howSearchSurname" text="How do I search for my Chinese surname?" />
                </li>
                <li>
                  <mcr-wiki-glossary-link naturalId="knowCharacter" text="I know my surname character. Now what?" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import StandOutBlockImage from '@common/elements/layouts/StandOutBlockImage.vue';
import consts from '@common/utils/consts';
import {SEARCH_TAB_BY_DEFAULT_FORM, TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {sourceTypeCategories} from '@common/utils/consts.source';
import {s3ThumbnailLink} from '@common/utils/utils';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import {mapGetters} from 'vuex';

import AlphabetSearch from './AlphabetSearch';
import SearchSurnamesFormContainer from './SearchSurnamesFormContainer';
import SearchSurnamesResults from './SearchSurnamesResults';
import SearchSurnamesResultsEmpty from './SearchSurnamesResultsEmpty';

const mcrWikiGlossaryLink = () => import('@common/elements/glossary/mcrWikiGlossaryLink');

export default {
  metaInfo: {
    title: 'Search Chinese Surnames',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Search for your Chinese surname in Cantonese, Hokkien, Teochew and other dialects. Explore how your clan name is spelled around the world.',
      },
    ],
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      errors: {},
      notFoundSurname: '',
    };
  },
  watch: {
    '$route.query': {
      handler: function (toQuery, fromQuery) {
        if (isEmpty(toQuery)) {
          this.init();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'surnamesSearchFormTextState',
      'surnamesSearchFormTextStartState',
      'surnameExistsState',
      'surnameExistsLoadingState',
      'surnamesLoadingState',
      'userIsLoggedInState',
      'searchCategoriesListState',
    ]),
    isDesktop() {
      return this.$store.getters.windowWidthState >= this.$breakpoints.tablet;
    },
    scrollToElement() {
      return this.isDesktop ? '.search-surnames-page' : '.search-results-content';
    },
    scrollOffset() {
      return this.isDesktop ? -consts.MAIN_MENU_HEIGHT : -consts.MAIN_MENU_HEIGHT_MOBILE;
    },
    searchTouched() {
      return (
        this.surnameExistsLoadingState ||
        this.notFoundSurname ||
        this.surnamesLoadingState ||
        this.surnamesSearchFormTextStartState
      );
    },
    searchRecordsRoute() {
      return {name: 'search-all-records', query: {tab: TAB_ID_INDEXED_RECORDS}};
    },
    searchRecordsCategory() {
      return this.searchCategoriesListState.find(c => c.id === sourceTypeCategories.CATEGORY_EXCLUSION_RECORDS);
    },
    searchRecordsCategoryImage() {
      const category = this.searchRecordsCategory;
      return category && category.wiki_article && category.wiki_article.image
        ? s3ThumbnailLink(category.wiki_article.image, 600)
        : '';
    },
    searchZupusRoute() {
      if (!this.searchZupusCategory) {
        return this.searchRecordsRoute;
      }
      const tab = SEARCH_TAB_BY_DEFAULT_FORM[this.searchZupusCategory.default_form];
      const query = {category_id: this.searchZupusCategory.id, tab};
      return {name: 'search-all-records', query};
    },
    searchZupusCategory() {
      return this.searchCategoriesListState.find(c => c.id === sourceTypeCategories.CATEGORY_ZUPUS);
    },
    searchZupusCategoryImage() {
      const category = this.searchZupusCategory;
      return category && category.wiki_article && category.wiki_article.image
        ? s3ThumbnailLink(category.wiki_article.image, 600)
        : '';
    },
  },
  methods: {
    init() {
      if (!this.searchCategoriesListState.length) {
        this.$store.dispatch('fetchSearchCategoriesListAction');
      }
      if (this.$route.query.q && this.$route.query.q === this.surnamesSearchFormTextState) {
        this.notFoundSurname = this.surnameExistsState ? '' : this.$route.query.q;
        return;
      }
      if (this.$route.query.start && this.$route.query.start === this.surnamesSearchFormTextStartState) {
        return;
      }
      this.parseFiltersFromQuery();
      if (this.$route.query.q) {
        return this.searchByText();
      }
      if (this.$route.query.start) {
        return this.searchByStartsWith();
      }
      this.clearAll();
    },
    onSubmit() {
      this.errors = this.validateForm();
      if (!isEmpty(this.errors)) {
        return;
      }
      this.getSearchedSurnameExists(this.surnamesSearchFormTextState);
    },
    validateForm() {
      const errors = {};
      if (!this.surnamesSearchFormTextState) {
        errors['search'] = 'Please input surname';
      }
      return errors;
    },
    searchByText() {
      const errors = this.validateForm();
      if (isEmpty(errors)) {
        this.getSearchedSurnameExists(this.surnamesSearchFormTextState);
      }
    },
    getSearchedSurnameExists(surname) {
      this.notFoundSurname = '';
      return this.$store.dispatch('getSurnameExistsAction', capitalize(surname)).then(exists => {
        this.afterSearchRan(surname, exists);
      });
    },
    afterSearchRan(surname, exists) {
      if (exists === 0) {
        this.notFoundSurname = surname;
      }
      if (exists === 1) {
        const word = capitalize(surname);
        if (!this.userIsLoggedInState) {
          this.$store.commit('setSurnamesRegisterState', [word]);
        }
        this.$router.push({name: 'search-tool-surname-detail', query: {word: word}});
      } else if (exists >= 1) {
        this.onAlphabetLetterClick(surname);
        this.$store.commit('setSurnamesSearchFormTextState', surname);
      }
    },
    parseFiltersFromQuery() {
      this.$store.commit('setSurnamesSearchFormTextState', this.$route.query.q || '');
      this.$store.commit('setSurnamesSearchFormTextStartState', this.$route.query.start || '');
    },
    onAlphabetLetterClick(start) {
      if (this.$route.query.start === start) {
        return;
      }
      this.$store.commit('setSurnamesSearchFormTextState', '');
      this.notFoundSurname = '';
      this.$router.push({query: {...this.$route.query, start}});
      this.$store.commit('setSurnamesSearchFormTextStartState', start);
      this.searchByStartsWith();
    },
    searchByStartsWith(page = 1) {
      const params = {start: this.surnamesSearchFormTextStartState, page};
      this.$store.dispatch('searchSurnamesAction', params);
    },
    onSwitchPage(page) {
      this.$scrollTo({x: 0, y: 0});
      this.searchByStartsWith(page);
    },
    clearAll() {
      this.$store.commit('setSurnamesListState', null);
      this.$store.commit('setSurnamesMetaState', {});
    },
  },
  components: {
    StandOutBlockImage,
    SearchSurnamesResults,
    AlphabetSearch,
    SearchSurnamesResultsEmpty,
    SearchSurnamesFormContainer,
    McrButton,
    mcrWikiGlossaryLink,
  },
};
</script>

<style lang="scss" scoped>
@import '~@common/style/search-tool-list.scss';
.search-surnames-page {
  .section {
    margin-top: 50px;
  }

  .records-section {
    border-bottom: 1px solid $divider-line-light;
    padding-bottom: 10px;
    margin-bottom: 24px;

    .records-items {
      margin: 12px 0;
      display: grid;
      grid-gap: 32px;
      grid-template-columns: repeat(2, 1fr);
      a {
        display: flex;
      }
    }

    @media only screen and (max-width: $breakpoint-mobile) {
      .records-items {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
</style>
