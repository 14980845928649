<template>
  <p class="alphabet-search">
    <a v-for="letter in alphabet" class="letter" @click="letterClick(letter)">{{ letter }}</a>
  </p>
</template>

<script>
export default {
  computed: {
    alphabet() {
      return 'abcdefghijklmnopqrstuvwxyz';
    },
  },
  methods: {
    letterClick(letter) {
      this.$emit('click', letter);
    },
  },
  name: 'AlphabetSearch',
};
</script>

<style lang="scss" scoped>
.alphabet-search {
  display: flex;
  flex-wrap: wrap;
  .letter {
    padding: 5px 10px;
    text-transform: uppercase;
  }
}
</style>
