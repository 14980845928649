<template>
  <div class="search-surnames-results" v-if="surnamesListState">
    <base-pagination-meta :meta="surnamesMetaState"></base-pagination-meta>
    <div v-for="name in surnamesListState" class="surname-link-wrapper">
      <router-link :to="getToRoute(name)" class="visitable">{{ getSurnameDisplay(name) }}</router-link>
    </div>
    <hr />
    <base-pagination :meta="surnamesMetaState" @onSwitchPage="onSwitchPage"></base-pagination>
  </div>
</template>

<script>
import BasePagination from '@common/elements/base-pagination';
import BasePaginationMeta from '@common/elements/base-pagination-meta';
import capitalize from 'lodash/capitalize';
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['surnamesListState', 'surnamesMetaState']),
  },
  methods: {
    getToRoute(surname) {
      const word = surname.name_hant || surname.name_ch || surname.pinyin;
      return {name: 'search-tool-surname-detail', query: {word: capitalize(word)}};
    },
    getSurnameDisplay(surname) {
      const pinyin = surname.pinyin ? capitalize(surname.pinyin) : '';
      const name_hant = surname.name_hant && surname.name_ch !== surname.name_hant ? surname.name_hant : '';
      const name_ch = name_hant ? `${surname.name_ch} / ${name_hant}` : surname.name_ch;
      return name_ch ? `${name_ch} (${pinyin})` : pinyin;
    },
    onSwitchPage(page) {
      this.$emit('onSwitchPage', page);
    },
  },
  name: 'SearchSurnamesResults',
  components: {BasePaginationMeta, BasePagination},
};
</script>

<style lang="scss" scoped>
.search-surnames-results {
  .surname-link-wrapper {
    margin: 10px 0;
  }
  .base-pagination-meta {
    margin-bottom: 24px;
  }
  hr {
    margin: 22px 0;
  }
}
</style>
